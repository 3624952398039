<template>
  <div class="sub-payment" v-if="cart.length > 0">
    <div class="sub-payment-header" style="color: white">
      <div class="back">
        <img src="@/assets/svg/v2/arrowToLeft.svg" alt="Back" class="themed-img"/>
        <button @click="back()">{{ $t('subscriptions.back') }}</button>
      </div>
    </div>

    <div class="sub-payment-body">
      <div class="payment-informations">
        <div
          v-if="getUserDetails?.address.account_type === 0"
          class="contact-billing"
        >
          <div class="contact">
            <h3>{{ $t('subscriptions.contact_informations') }}</h3>
            <p>
              {{ getUserDetails?.identity.firstname.toUpperCase() }}
              {{ getUserDetails?.identity.lastname.toUpperCase() }} -
              {{ getUserDetails?.address.phone }}
            </p>
            <button @click="$router.push('/auth/account/details')">
              {{ $t('subscriptions.update') }}
            </button>
          </div>
          <div class="billing">
            <h3>{{ $t('subscriptions.billing') }}</h3>
            <p>
              {{ getUserDetails?.address.address.toUpperCase() }},
              {{ getUserDetails?.address.zip }}
              {{ getUserDetails?.address.city.toUpperCase() }}
            </p>
            <button @click="$router.push('/auth/account/details')">
              {{ $t('subscriptions.update') }}
            </button>
          </div>
        </div>
        <div v-else class="contact-billing">
          <div class="contact">
            <h3>{{ $t('subscriptions.contact_informations') }}</h3>
            <p>{{ getUserDetails?.address.organization.name.toUpperCase() }}</p>
          </div>
          <div class="billing">
            <h3>{{ $t('subscriptions.billing') }}</h3>
            <p>
              {{ getUserDetails?.address.organization.address.toUpperCase() }},
              {{ getUserDetails?.address.organization.zip }}
              {{ getUserDetails?.address.organization.city.toUpperCase() }}
            </p>
            <button @click="$router.push('/auth/account/details')">
              {{ $t('subscriptions.update') }}
            </button>
          </div>
        </div>

        <h1>{{ $t('subscriptions.payment_method') }}</h1>

        <div class="subtitle">
          <img
            src="@/assets/svg/v2/registerProcess/mangopaySecure.svg"
            class="themed-img"
            alt="Secure"
          />
          <p>{{ $t('subscriptions.payment_method_description') }}</p>
        </div>

        <div class="payment">
          <div class="payment-sources">
            <div class="payment-sources-selection">
              <img
                src="@/assets/svg/v2/dotActive.svg"
                alt="Selected banking card"
              />
              <p>{{ $t('subscriptions.banking_card') }}</p>
            </div>
            <div class="payment-sources-accepted">
              <img src="@/assets/svg/v2/subscriptions/visa.svg" alt="Visa" />
              <img
                src="@/assets/svg/v2/subscriptions/mastercard.svg"
                alt="Mastercard"
              />
              <img src="@/assets/svg/v2/subscriptions/ae.svg" alt="AE" />
            </div>
          </div>

          <form>
            <el-input
              class="form-input"
              :class="{ errorInput: cardRegex.card_number }"
              :placeholder="$t('subscriptions.card_number')"
              type="text"
              minlength="15"
              maxlength="20"
              v-model="card.card_number"
              :size="size"
              :suffix-icon="Lock"
            />
            <el-input
              class="form-input"
              :class="{ errorInput: cardRegex.fullnames }"
              :placeholder="$t('subscriptions.card_fullnames')"
              type="text"
              minlength="5"
              maxlength="75"
              v-model="card.fullnames"
              :size="size"
            />

            <div>
              <el-input
                class="form-input"
                clearable
                :class="{ errorInput: cardRegex.card_expiration_date }"
                :placeholder="$t('subscriptions.card_expiration')"
                type="text"
                minlength="5"
                maxlength="10"
                v-model="card.card_expiration_date"
                :size="size"
              />
              <el-input
                class="form-input"
                :class="{ errorInput: cardRegex.card_cvx }"
                :placeholder="$t('subscriptions.card_code_security')"
                type="text"
                minlength="3"
                maxlength="3"
                v-model="card.card_cvx"
                :size="size"
                :suffix-icon="QuestionFilled"
              />
            </div>
          </form>
        </div>
      </div>
      <div class="cart">
        <div class="cart-drawer-content">
          <h3 class="premium">{{ $t('settings.subscriptions.premium') }}</h3>

          <div class="cart-drawer-subscriptions">
            <div v-for="(item, n) in cart" :key="n" class="subscriptions-items">
              <div class="item-description">
                <img
                  class="spirit-type"
                  :src="dynamicPath(item.path)"
                  :alt="item.label"
                  width="58"
                  height="58"
                />

                <div>
                  <h3>{{ item.label }}</h3>
                  <p v-if="item.duration === 'm'">
                    {{ $t('subscriptions.month') }}
                  </p>
                  <p v-else>{{ $t('subscriptions.year') }}</p>
                </div>
              </div>

              <div class="item-price">
                <h3>{{ item.price }}€</h3>
              </div>
            </div>
          </div>

          <div class="cart-drawer-subscriptions-infos">
            <div>
              <h4>{{ $t('subscriptions.sub_total') }}</h4>
              <p>{{ totalCart }}€</p>
            </div>
          </div>

          <el-divider />

          <div class="cart-drawer-total">
            <div>
              <h4>{{ $t('subscriptions.total') }}</h4>
              <p>{{ $t('subscriptions.include_vat') }}</p>
            </div>
            <p>{{ totalCart }}€</p>
          </div>

          <button class="generic-btn" @click="subscriptionsPayment()">
            {{ $t('subscriptions.pay') }}
          </button>
        </div>

        <div class="mangopay">
          <img src="@/assets/svg/mangopayGrey.svg" alt="Mangopay" class="themed-img"/>
        </div>
      </div>
    </div>

    <payment3DSecureVue
      v-if="get3DSecureDialog"
      :cart="cart"
      @clear="clearCart()"
    ></payment3DSecureVue>
  </div>
</template>

<script setup>
import { QuestionFilled, Lock } from '@element-plus/icons-vue'
</script>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ElMessage, ElLoading } from 'element-plus'
import payment3DSecureVue from '@/components/modules/payments/payment3DSecure.vue'

export default {
  components: {
    payment3DSecureVue,
  },
  data() {
    return {
      size: 'large',

      cart: [],

      card: {
        fullnames: '',
        card_number: '',
        card_expiration_date: '',
        card_cvx: '',
        card_type: '',
      },

      cardRegex: {
        fullnames: false,
        card_number: false,
        card_expiration_date: false,
        card_cvx: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      getUserDetails: 'getUserDetails',
      get3DSecureDialog: 'get3DSecureDialog',
    }),
    totalCart() {
      let total = 0
      this.cart.forEach((item) => {
        total += item.price
      })
      return parseFloat(total).toFixed(2)
    },
  },
  watch: {
    'card.card_expiration_date': function () {
      if (this.card.card_expiration_date) {
        let slashCount = 0
        for (var i = 0; i < this.card.card_expiration_date.length; i++) {
          if (this.card.card_expiration_date[i] === '/') {
            slashCount++
          }
        }

        if (slashCount > 1) {
          this.card.card_expiration_date = ''
        }

        if (this.card.card_expiration_date.length === 2) {
          this.card.card_expiration_date += '/'
        }
      }
    },
  },
  mounted() {
    const saveLS = localStorage.getItem('subscriptionsCart')

    if (saveLS) {
      this.cart = JSON.parse(saveLS)

      if (this.cart.length === 0) {
        this.$router.push('/subscriptions')
      }
    } else {
      this.$router.push('/subscriptions')
    }

    if (!this.getUserDetails) {
      this.getClient()
    }
  },
  methods: {
    ...mapActions({
      getClient: 'getAccountDetails',
      payment: 'subscriptionsPayment',
    }),
    clearCart() {
      this.card = {
        fullnames: '',
        card_number: '',
        card_expiration_date: '',
        card_cvx: '',
        card_type: '',
      }

      localStorage.removeItem('subscriptionsCart')
      this.$router.push('/auth/account/details?subscription=true')
    },
    dynamicPath(path) {
      return require(`@/assets/svg/v2/categories/${path}`)
    },
    back() {
      this.$router.go(-1)
    },
    async subscriptionsPayment() {
      const regex_card_number =
        /^(4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|3[47][0-9]{13,16})$/
      const regex_card_fullnames =
        /^([a-zA-ZÀ-ÖØ-öø-ÿ'’-]+(?:\s+[a-zA-ZÀ-ÖØ-öø-ÿ'’-]+)*)$/
      const regex_card_expiration = /^(0[1-9]|1[0-2])\/([0-9]{2})$/
      const regex_card_cvx = /^[0-9]{3,4}$/

      if (!regex_card_number.test(this.card.card_number)) {
        this.cardRegex.card_number = true
        ElMessage({
          message: this.$t('register.step_3.error_card_number'),
          type: 'warning',
          grouping: true,
        })
      } else {
        this.cardRegex.card_number = false
      }

      if (!regex_card_expiration.test(this.card.card_expiration_date)) {
        this.cardRegex.card_expiration_date = true
        ElMessage({
          message: this.$t('register.step_3.error_card_expiration_date'),
          type: 'warning',
          grouping: true,
        })
      } else {
        this.cardRegex.card_expiration_date = false
      }

      if (
        !regex_card_fullnames.test(this.card.fullnames) ||
        this.card.fullnames.length < 1
      ) {
        this.cardRegex.fullnames = true
        ElMessage({
          message: this.$t('register.step_3.error_card_fullnames'),
          type: 'warning',
          grouping: true,
        })
      } else {
        this.cardRegex.fullnames = false
      }

      if (!regex_card_cvx.test(this.card.card_cvx)) {
        this.cardRegex.card_cvx = true
        ElMessage({
          message: this.$t('register.step_3.error_card_cvx'),
          type: 'warning',
          grouping: true,
        })
      } else {
        this.cardRegex.card_cvx = false
      }

      if (
        !this.cardRegex.card_number &&
        !this.cardRegex.card_expiration_date &&
        !this.cardRegex.card_cvx &&
        !this.cardRegex.fullnames
      ) {
        const payload = {
          card: this.card,
          subscriptions: [],
        }

        payload.browserInfos = {
          AcceptHeader:
            'text/html, application/xhtml+xml, application/xml;q=0.9, /;q=0.8',
          JavaEnabled: navigator.javaEnabled(),
          Language: navigator.language,
          ColorDepth: window.screen.colorDepth,
          ScreenHeight: window.screen.height,
          ScreenWidth: window.screen.width,
          TimeZoneOffset: new Date().getTimezoneOffset(),
          UserAgent: navigator.userAgent,
          JavascriptEnabled: true,
        }

        this.cart.forEach((item) => {
          const convertItem = {
            type: item.id,
            period: null,
          }

          if (item.duration === 'a') {
            convertItem.period = 1
          } else {
            convertItem.period = 0
          }

          payload.subscriptions.push(convertItem)
        })

        const loading = ElLoading.service({
          lock: true,
          background: 'rgba(0, 0, 0, 0.7)',
        })

        await this.payment(payload)
          .then(() => {
            this.$router.push('/auth/account/details?subscription=true')
            localStorage.removeItem('subscriptionsCart')
            loading.close()
            this.cart = []
          })
          .catch(() => {
            loading.close()
          })
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root.scss';
.sub-payment-body,
.back,
.contact,
.billing,
.subtitle,
.payment-sources,
.payment-sources-selection,
form div {
  display: flex;
}

.sub-payment {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 66px;
  margin: auto;
  margin-bottom: 80px;
  max-width: 1400px;
}

.sub-payment-header {
  margin-top: 48px;
  margin-bottom: 43px;
  text-align: start;
  width: 100%;

  .back {
    margin-bottom: 23px;

    button {
      background: transparent;
      border: 0;
      color: var(--subtitle-text-color);
      font-size: 15px;
      font-weight: 500;
    }
  }
}

.sub-payment-body {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .payment-informations {
    margin-right: 100px;

    .contact-billing {
      border-radius: 12px;
      border: 1px solid var(--border);
      background: var(--background-color);
      margin-bottom: 47px;

      .contact,
      .billing {
        padding: 20px 17px;

        h3 {
          color: var(--subtitle-text-color);
          font-size: 16px;
          font-weight: 500;
          min-width: 190px;
        }

        p {
          color: var(--text-color);
          font-size: 16px;
          font-weight: 500;
          min-width: 400px;
        }

        button {
          background: transparent;
          border: 0;
          color: var(--light-blue);
          font-size: 15px;
          font-weight: 600;
        }
      }

      .contact {
        border-bottom: 1px solid var(--border);
      }
    }

    h1 {
      margin-bottom: 16px;
    }

    .subtitle {
      align-items: start;
      margin-bottom: 35px;

      img {
        margin-right: 8px;
      }

      p {
        font-size: 15px;
        font-weight: 500;
      }
    }

    .payment {
      border-radius: 12px;
      border: 1px solid var(--border);
      background: var(--background-color);
      overflow: hidden;

      .payment-sources {
        align-items: center;
        justify-content: space-between;
        padding: 17px;

        .payment-sources-selection {
          align-items: center;
          cursor: pointer;

          p {
            color: var(--text-color);
            font-size: 16px;
            font-weight: 500;
            margin-left: 8px;
          }
        }

        .payment-sources-accepted {
          display: flex;
          justify-content: space-between;
          min-width: 130px;
        }
      }

      form {
        flex-direction: column;
        padding: 23px;
        background: var(--background-color-2);
        border-top: 1px solid var(--border);

        .form-input {
          border-radius: 5px;
          border: 1px solid var(--border);
          background: var(--background-color-2);
          margin-bottom: 16px;
          padding-left: 15px;
        }

        div {
          .form-input:nth-child(1) {
            margin-right: 20px;
          }
        }
      }
    }
  }
}

.mangopay {
  display: flex;
  justify-content: center;
  margin-top: 29px;
  
  img {
    max-width: 180px;
  }
}

.cart-drawer-content {
  border-radius: 12px;
  border: 1px solid var(--border);
  background: var(--background-color-2);
  min-width: 444px;
  padding: 24px 17px;

  .premium {
    font-family: Hyperwave;
    color: var(--text-color);
    font-size: 22px;
    font-weight: 700;
  }

  .cart-drawer-subscriptions {
    margin-top: 36px;

    .subscriptions-items {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .item-description {
        display: flex;
        align-items: center;

        img {
          margin-right: 16px;
        }

        div {
          h3 {
            color: var(--text-color);
            font-size: 18px;
            font-weight: 600;
          }

          p {
            color: var(--subtitle-text-color);
            font-size: 15px;
            font-weight: 500;
          }
        }
      }

      .item-price {
        display: flex;
        flex-direction: column;
        align-items: end;

        h3 {
          color: var(--text-color);
          font-size: 16px;
          font-weight: 600;
        }

        button {
          background: transparent;
          border: 0;
          color: var(--subtitle-text-color);
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
  }

  .cart-drawer-subscriptions-infos {
    div {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      h4 {
        color: var(--subtitle-text-color);
        font-size: 16px;
        font-weight: 500;
      }

      p {
        color: var(--text-color);
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .cart-drawer-total {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 42px;

    div {
      h4 {
        color: var(--subtitle-text-color);
        font-size: 20px;
        font-weight: 500;
      }

      p {
        color: var(--subtitle-2-text-color);
        font-size: 14px;
        font-weight: 500;
      }
    }

    p {
      color: var(--text-color);
      font-size: 16px;
      font-weight: 500;
    }
  }

  button {
    max-width: 412px;
    margin: auto;
  }
}

@media screen and (max-width: 500px) {
  .sub-payment {
    padding: 0px;
    margin-bottom: 15px;

    .sub-payment-header {
      margin-top: 20px;
      margin-bottom: 10px;
      padding-left: 10px;
    }

    .payment-informations {
      display: flex;
      margin-right: 0;
      flex-direction: column;
      align-items: center;

      .payment {
        border-radius: 0;
        border-left: 0;
        border-right: 0;
      }

      .subtitle {
        margin-left: 10px;
      }

      .contact-billing {
        margin-bottom: 25px;
        border-left: 0;
        border-right: 0;
        border-radius: 0;

        .contact,
        .billing {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          width: 350px;

          h3,
          p {
            width: auto;
            min-width: auto !important;
            margin-bottom: 10px;
          }

          p {
            font-size: 14px;
          }

          button {
            max-width: 65px;
            margin-left: 0;
          }
        }
      }
    }

    .sub-payment-body {
      flex-wrap: wrap;

      .cart {
        margin: auto;
        margin-top: 20px;
        width: 100%;

        .cart-drawer-content {
          min-width: auto;
          border-radius: 0;
          border-left: 0;
          border-right: 0;
        }

        .generic-btn {
          width: 200px;
        }
      }
    }
  }
}
</style>
